import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";
import ShilohAlignmentUpdate from "./ShilohAlignmentUpdate";

const ShilohAlignment = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const today = new Date(Date.now());
  const token = useAuth().token;
  const [data, setData] = useState([]);
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);

  function convertTimeToDate(dateString) {
    const newDate = moment.utc(dateString).local().format("MMM Do YYYY");
    return newDate;
  }

  const handleActionClick = (e) => {
    const newData = data.filter((value) => {
      return value.id === e;
    });

    setActionModalData(newData);
    setIsActionModalOpen(true);
  };

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getShilohAlignment();
  };

  const getShilohAlignment = async () => {
    try {
      const response = await fetch(`${base_url}/client/shilohAlignment`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log(`outer data : `, data);
      setData(data);
    } catch (error) {
      console.error("Error fetching Shiloh Alignment:", error);
    }
  };

  useEffect(() => {
    getShilohAlignment();
  }, []);

  return (
    <div className="">
      <h3 className="pt-4">Shiloh Alignments</h3>
      <hr />

      <div className="card mb-4">
        <div className="card-header text-center">New Client Details</div>
        <div className="container-fluid px-0">
          <table border="1" className="table table-striped table-hover">
            <thead>
              <tr className="text-center">
                <th scope="col">Id </th>
                <th scope="col">Name </th>
                <th scope="col">Intake Date </th>
                <th scope="col">Program Completion Date </th>
                <th scope="col">Active Status </th>
                <th scope="col">Email </th>
                <th scope="col">Phone </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {data.map((row) => (
                <tr className=" " key={row.id}>
                  <td className="align-middle">{row.id}</td>
                  <td className="align-middle">{row.client_name}</td>
                  <td className="align-middle">
                    {convertTimeToDate(row.program_intake_date)}
                  </td>
                  <td
                    className={`align-middle ${
                      new Date(row.program_completion_date).getTime() <=
                      today.getTime()
                        ? "fw-bolder text-danger"
                        : ""
                    } `}
                  >
                    {convertTimeToDate(row.program_completion_date)}
                  </td>
                  <td
                    className={`align-middle ${
                      row.status == 0 ? "fw-bolder text-danger" : ""
                    } `}
                  >
                    {row.status == 1 ? "Active" : "Inactive"}
                  </td>
                  <td className="align-middle">
                    {row.email == "" ? "No Email" : row.email}
                  </td>
                  <td className="align-middle">
                    {row.phone == undefined ? "No Phone" : row.phone}
                  </td>
                  <td className="align-middle">
                    <button
                      className="btn btn-primary"
                      // style={{ height: "35px" }}
                      onClick={() => handleActionClick(row.id)}
                    >
                      Action
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isActionModalopen && (
            <ShilohAlignmentUpdate
              isOpen={isActionModalopen}
              onClose={closeActionModal}
              data={actionModalData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShilohAlignment;
