import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";

const AddClient = ({ isOpen, onClose }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [intakeDate, setIntakeDate] = useState("");
  const [programCompletionDate, setProgramCompletionDate] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setName("");
      setIntakeDate("");
      setProgramCompletionDate("");
      setEmail("");
      setPhone("");
    }
  }, [isOpen]);

  const handleAddClick = () => {
    if (name == "" || intakeDate == "" || programCompletionDate == "") {
      toast.warn("Please fill required fields");
      return;
    }
    setLoading(true);
    const payload = {
      name: name,
      intakeDate: intakeDate,
      programCompletionDate: programCompletionDate,
      activeStatus: 1,
      email: email,
      phone: phone,
      updatedBy: userId,
    };

    fetch(`${base_url}/client/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          toast.warn("Failed to add Client");
          throw new Error("Failed to add Client");
        }
        toast.success("Client added Successful!");
        setName("");
        setEmail("");
        setPhone("");
        setIntakeDate("");
        setProgramCompletionDate("");
        onClose();
      })
      .catch((error) => {
        console.error("Error adding Client:", error);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add A Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="name" className="w-25">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-control "
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="start" className="w-25">
                Start Date
              </label>
              <input
                type="date"
                id="start"
                className="form-control "
                value={intakeDate}
                onChange={(e) => setIntakeDate(e.target.value)}
                placeholder="Intake Date"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="end" className="w-25">
                End Date
              </label>
              <input
                type="date"
                id="end"
                className="form-control"
                value={programCompletionDate}
                onChange={(e) => setProgramCompletionDate(e.target.value)}
                placeholder="Program Completion Date"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="email" className="w-25">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email (Optional)"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="phone" className="w-25">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                maxLength={10}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone (Optional)"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            Add Client
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddClient;
