import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../context/useAuth";
import { FaUser, FaLock, FaEyeSlash, FaEye } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";

const Login = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formDataLogin, setFormDataLogin] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmitLogin = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await fetch(`${base_url}/user/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(formDataLogin),
      });
      if (response.status == 200) {
        const data = await response.json();

        if (data["exists"]) {
          setFormDataLogin({ username: "", password: "" });
          var username = formDataLogin.username;
          var token = data.token;
          var role = data.role;
          var userId = data.userId;
          await login({ username, token, role, userId });
          toast.success("Login Successful!");
          setLoading(false);
          navigate("/dashboard");
        } else {
          setLoading(false);
          toast.warn("Login unsuccessful!");
        }
      }
    } catch (error) {
      toast.warn("Login unsuccessful!");
      setLoading(false);
    }
  };

  const handleChangeLogin = (event) => {
    const { name, value } = event.target;
    setFormDataLogin({ ...formDataLogin, [name]: value });
  };

  const styles = {
    login_oueter: {
      width: "460px",
      maxWidth: "100%",
    },
    logo_outer: {
      textAlign: "center",
    },
    logo_outer_img: {
      width: "320px",
      marginBottom: "20px",
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div
          className="row d-flex justify-content-center align-items-center m-0"
          style={{ height: "100vh" }}
        >
          <div style={styles.login_oueter}>
            <div className="col-md-12 logo_outer" style={styles.logo_outer}>
              <img
                src="https://hypecounselingservices.org/wp-content/uploads/2020/06/HYPE_CARF-Logo-REAL-BIG-GOLD.png"
                alt=""
                style={styles.logo_outer_img}
              />
            </div>
            <form
              id="login"
              //   autocomplete="off"
              className="bg-light border p-3"
              onSubmit={handleSubmitLogin}
            >
              <div className="form-row">
                <h4 className="title my-3">Login For Access</h4>
                <div className="col-12">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="">
                          <FaUser />
                        </i>
                      </span>
                    </div>
                    <input
                      id="name"
                      name="username"
                      type="text"
                      value={formDataLogin.username}
                      onChange={handleChangeLogin}
                      className="input form-control"
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <i className="">
                          <FaLock />
                        </i>
                      </span>
                    </div>
                    <input
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={formDataLogin.password}
                      onChange={handleChangeLogin}
                      className="input form-control"
                      id="password"
                      placeholder="password"
                      required="true"
                      aria-label="password"
                      aria-describedby="basic-addon1"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        onClick={togglePasswordVisibility}
                      >
                        {!showPassword ? (
                          <i>
                            <FaEye />
                          </i>
                        ) : (
                          <i>
                            <FaEyeSlash />
                          </i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    name="signin"
                  >
                    {loading ? (
                      <div>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <span>Login</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
