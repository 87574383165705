import { useAuth } from "../context/useAuth";
import { Navigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated

    return <Navigate to="/" />;
  }

  return children;
};
