import React, { useState } from "react";
import FilterForm from "./FilterForm";
import FilterTable from "./FilterTable";
import { useAuth } from "../../../context/useAuth";

const Reports = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const [visible, setVisible] = useState(false);
  const [report, setReport] = useState([]);
  const [obj, setObj] = useState({});

  function toggleVisibility(dept, subdept, client) {
    submitBehaviour(
      dept === -1 ? undefined : obj["deptID"],
      subdept === -1 ? undefined : obj["subdeptID"],
      client === -1 ? -1 : obj["clientID"],
      obj["startDate"],
      obj["endDate"]
    );
  }

  function fetcher(data) {
    setObj(data);
  }

  function submitBehaviour(
    deptID,
    subdeptID,
    clientID,
    startDate,
    endDate,
    attendance,
    excusedAbsence
  ) {
    // setLoading(true);
    var availability = {
      deptID: 1,
      subdeptID: 1,
      clientID: clientID == -1 ? -1 : 1,
      startDate: 1,
      endDate: 1,
    };
    if (deptID === "") {
      deptID = -1;
      availability["deptID"] = -1;
    }
    if (subdeptID === "") {
      subdeptID = -1;
      availability["subdeptID"] = -1;
    }
    // console.log(deptID, subdeptID, clientID, startDate, endDate);
    const payload = {
      departmentID: deptID,
      subDepartmentID: subdeptID,
      clientID: clientID,
      startDate: startDate,
      endDate: endDate,
      attendance: attendance,
      excusedAbsence: excusedAbsence,
    };

    // console.log(`payload : `, payload);
    fetch(`${base_url}/appointment/report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch report");
        }
        var data = await response.json();
        console.log(`report data : `, data["message"]);
        setReport([data["message"], availability, true]);
      })
      .catch((error) => {
        console.error("Error adding Client:", error);
      })
      .finally(() => {
        // setLoading(false);
        setVisible(true);
      });
  }

  return (
    <>
      <div className="">
        <FilterForm onSubmit={submitBehaviour} fetchData={fetcher} />
        <br />
        {visible ? (
          <FilterTable toggleVisibility={toggleVisibility} report={report} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Reports;
