import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";

const UpdateClient = ({ isOpen, onClose, data }) => {
  const token = useAuth().token;
  const role = useAuth().role;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState(data[0].name);
  const [loading, setLoading] = useState(false);
  const [intakeDate, setIntakeDate] = useState("");
  const [programCompletionDate, setProgramCompletionDate] = useState("");
  const [email, setEmail] = useState(data[0].Email);
  const [phone, setPhone] = useState(data[0].Phone);
  const [status, setStatus] = useState(data[0].ActiveStatus);

  const handleAddClick = () => {
    if (email !== "" && !validateEmail(email)) {
      toast.warn("Email is Invalid!");
      return;
    }
    setLoading(true);
    const payload = {
      ClientID: data[0].ClientID,
      name: name,
      intakeDate: intakeDate,
      programCompletionDate: programCompletionDate,
      activeStatus: status,
      email: email,
      phone: phone,
      updatedBy: userId,
    };

    fetch(`${base_url}/client/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          toast.warn("Failed to Update Client");
          throw new Error("Failed to Update Client");
        }
        toast.success("Client Updated Successful!");
        onClose();
      })
      .catch((error) => {
        console.error("Error Updating Client:", error);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    let startDate = moment.utc(data[0].IntakeDate).local().format("YYYY-MM-DD");
    setIntakeDate(startDate);

    let endDate = moment
      .utc(data[0].ProgramCompletionDate)
      .local()
      .format("YYYY-MM-DD");
    setProgramCompletionDate(endDate);
  }, []);

  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Client Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="name" className="w-25">
                Name
              </label>
              {role === "Super Admin" ? (
                <input
                  type="text"
                  id="name"
                  className="form-control "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  // disabled
                />
              ) : (
                <input
                  type="text"
                  id="name"
                  className="form-control "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  disabled
                />
              )}
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="start" className="w-25">
                Start Date
              </label>
              {role === "Super Admin" ? (
                <input
                  type="date"
                  id="start"
                  className="form-control "
                  value={intakeDate}
                  onChange={(e) => setIntakeDate(e.target.value)}
                  placeholder="Intake Date"
                  // disabled
                />
              ) : (
                <input
                  type="date"
                  id="start"
                  className="form-control "
                  value={intakeDate}
                  onChange={(e) => setIntakeDate(e.target.value)}
                  placeholder="Intake Date"
                  disabled
                />
              )}
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="end" className="w-25">
                End Date
              </label>
              {role === "Super Admin" ? (
                <input
                  type="date"
                  id="end"
                  className="form-control"
                  value={programCompletionDate}
                  onChange={(e) => setProgramCompletionDate(e.target.value)}
                  placeholder="Program Completion Date"
                  // disabled
                />
              ) : (
                <input
                  type="date"
                  id="end"
                  className="form-control"
                  value={programCompletionDate}
                  onChange={(e) => setProgramCompletionDate(e.target.value)}
                  placeholder="Program Completion Date"
                  disabled
                />
              )}
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="email" className="w-25">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email (Optional)"
              />
              {/* <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => {
                  const value = e.target.value;
                  setEmail(value);
                  validateEmail(value);
                }}
                placeholder="Email (Optional)"
              /> */}
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="phone" className="w-25">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                maxLength={10}
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setPhone(value);
                  } else {
                    toast.warn("Enter Numeric Value Only!");
                  }
                }}
                placeholder="Phone (Optional)"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="phone" className="w-25">
                Status
              </label>
              <select
                id="dropdown"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                className="form-select"
                aria-label="Default select example"
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            Update Client
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateClient;
