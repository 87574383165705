import React, { useState } from "react";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const UpdateSubDepartment = ({ isOpen, onClose, data }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [department, setDepartment] = useState(data[0].split(":")[1]);
  const [subdepartment, setSubdepartment] = useState(data[0].split(":")[2]);
  const [slotDuration, setSlotDuration] = useState(data[0].split(":")[3]);

  const handleAddClick = () => {
    console.log(`data : `, data[0]);
    if (subdepartment === "") {
      toast.warn("SubDepartment Should not be Empty!!");
      return;
    }

    if (slotDuration === "") {
      toast.warn("slotDuration Should not be Empty!!");
      return;
    }

    const payload = {
      SubDepartmentId: data[0].split(":")[4],
      SubDepartmentName: subdepartment,
      SlotDuration: slotDuration,
      updatedBy: userId,
    };
    console.log(`payload : `, payload);
    // return;

    fetch(`${base_url}/subdepartment/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          toast.warn("Failed to Update Department");
          throw new Error("Failed to Update Department");
        }
        toast.success("Department Updated Successful!");
        onClose();
      })
      .catch((error) => {
        console.error("Error Updating Department:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Sub Department Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <label htmlFor="department" className="">
            Department:
          </label>
          <input
            type="text"
            id="name"
            className="form-control "
            value={department}
            //   onChange={(e) => setdepartmentName(e.target.value)}
            placeholder="Department Name"
            disabled
          />
        </div>

        <div className="mb-2">
          <label className="" htmlFor="subdepartment">
            Sub Department:
          </label>
          <input
            type="text"
            className="form-control w-100 mt-1"
            id="subdepartment"
            value={subdepartment}
            onChange={(e) => setSubdepartment(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <label className="" htmlFor="slotDuration">
            Slot Duration (in minutes):
          </label>
          <input
            type="number"
            className="form-control w-100 mt-1"
            id="slotDuration"
            value={slotDuration}
            onChange={(e) => setSlotDuration(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddClick}>
          Update Sub Department
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateSubDepartment;
