import React from "react";
import Sidebar from "../components/Sidebar";
import ShilohAlignment from "../components/optionScreens/shilohAlignment/ShilohAlignment";
import { useNavigate } from "react-router-dom";

const ShilohAlignmentPage = () => {
  const navigate = useNavigate();
  const handleOptionChange = (option) => {
    navigate(option.url);
  };

  return (
    <>
      <div className="d-flex flex-row w-100">
        <Sidebar handleOptionChange={handleOptionChange} />
        <div
          style={{ marginLeft: "300px", paddingRight: "30px" }}
          className="w-100"
        >
          <ShilohAlignment />
        </div>
      </div>
    </>
  );
};

export default ShilohAlignmentPage;
