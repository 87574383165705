import React, { useState } from "react";
import { CSVDownload } from "react-csv";
import moment from "moment";

const FilterTable = ({ report, toggleVisibility }) => {
  const [download, setDownload] = useState(false);

  var reportData = report[0];
  let data1 = [];

  function convertTimeToDate(dateString) {
    const newDate = moment(dateString, "YYYY-MM-DD").format("MMM Do YYYY");
    return newDate;
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  var dataForCSV = [
    [
      "AppointmentID",
      "Client",
      "Department",
      "Subdepartment",
      "StartTime",
      "EndTime",
      "Attendance",
      "Date",
      "ExcusedAbsence",
      "Updatedby",
      "Lastupdate",
    ],
  ];

  for (var i = 0; i < reportData.length; i++) {
    data1.push({
      AppointmentID: reportData[i]["id"],
      ClientName: reportData[i]["client_name"],
      DepartmentName: reportData[i]["department_name"],
      SubdepartmentName: reportData[i]["sub_department_name"],
      StartTime: reportData[i]["start_time"],
      EndTime: reportData[i]["end_time"],
      Attendance: reportData[i]["attendance"],
      Date: reportData[i]["date"],
      ExcusedAbsence: reportData[i]["excused_absence_comment"],
      Updatedby: reportData[i]["username"],
      Lastupdate: reportData[i]["updated_at"],
    });
    dataForCSV.push([
      reportData[i]["id"],
      reportData[i]["client_name"],
      reportData[i]["department_name"],
      reportData[i]["sub_department_name"],
      convertTimeToHHMM(reportData[i]["start_time"]),
      convertTimeToHHMM(reportData[i]["end_time"]),
      reportData[i]["attendance"] == 1 ? "Present" : "Absent",
      convertTimeToDate(reportData[i]["date"]),
      reportData[i]["excused_absence_comment"],
      reportData[i]["username"],
      reportData[i]["updated_at"],
    ]);
  }

  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  return (
    <div className="  ">
      <button type="button" className="btn btn-success" onClick={downloadCSV}>
        Download CSV
      </button>{" "}
      &nbsp;&nbsp;
      {download ? <CSVDownload data={dataForCSV} target="_blank" /> : <></>}
      <br />
      <br />
      <div className="card mb-4">
        <div className="card-header text-center">Reports</div>
        <div className="container-fluid px-0">
          <table className="table table-striped table-hover ">
            <thead className="">
              <tr className="text-center">
                <th scope="col">Id </th>
                <th scope="col">Client </th>
                <th scope="col">Department </th>
                <th scope="col">Sub Department </th>
                <th scope="col">Date </th>
                <th scope="col">Start Time </th>
                <th scope="col">End Time </th>
                <th scope="col">Attendance </th>

                <th scope="col">Excused Absence</th>
                <th scope="col">Updated By </th>
                <th scope="col">Updated Time </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {data1.map((row) => (
                <tr key={row.AppointmentID}>
                  <td>{row.AppointmentID}</td>
                  <td>{row.ClientName}</td>
                  <td>{row.DepartmentName}</td>
                  <td>{row.SubdepartmentName}</td>
                  <td>{convertTimeToDate(row.Date)}</td>
                  <td>{convertTimeToHHMM(row.StartTime)}</td>
                  <td>{convertTimeToHHMM(row.EndTime)}</td>

                  <td>
                    {row.Attendance == 1
                      ? "Present"
                      : row.attendance === 0
                      ? "Absent"
                      : new Date(row.Date) <= new Date()
                      ? "Unchecked"
                      : "Future Booking"}
                  </td>

                  <td>{row.ExcusedAbsence}</td>
                  <td>{row.Updatedby}</td>
                  <td>
                    {new Date(row.Lastupdate).toLocaleString("en-US", {
                      timeZone: "America/New_York",
                    })}{" "}
                    ET
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FilterTable;
