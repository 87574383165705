import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faCog,
  faChartBar,
  faDesktop,
  faUserAstronaut,
} from "@fortawesome/free-solid-svg-icons";
import { IoLogOut } from "react-icons/io5";
import { useAuth } from "../context/useAuth";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const options = [
    { name: "Dashboard", icon: faHome, url: "/dashboard" },
    { name: "Calendar", icon: faUser, url: "/calendar" },
    { name: "Clients", icon: faUserAstronaut, url: "/client" },
    { name: "Appointment", icon: faCog, url: "/appointment" },
    { name: "Reports", icon: faChartBar, url: "/reports" },
  ];

  const optionForSuperAdmin = [
    { name: "Dashboard", icon: faHome, url: "/dashboard" },
    { name: "Calendar", icon: faUser, url: "/calendar" },
    { name: "Clients", icon: faUserAstronaut, url: "/client" },
    {
      name: "Shiloh Alignment",
      icon: faUserAstronaut,
      url: "/shilohAlignment",
    },
    { name: "Appointment", icon: faCog, url: "/appointment" },
    { name: "Departments", icon: faDesktop, url: "/department" },
    { name: "Sub Departments", icon: faDesktop, url: "/subdepartment" },
    { name: "Reports", icon: faChartBar, url: "/reports" },
    { name: "Users", icon: faUser, url: "/user" },
  ];

  const { logout, role } = useAuth();
  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <div
        className="d-flex flex-column h-100 flex-shrink-0 p-3 text-bg-dark position-fixed sidenav-menu"
        style={{ width: "280px" }}
      >
        <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <a href="/dashboard">
            <img
              width="240"
              height="120"
              src="https://hypecounselingservices.org/wp-content/uploads/2020/06/HYPE_CARF-Logo-REAL-BIG-GOLD.png"
              alt="logo"
            />
          </a>
        </div>
        <hr />
        {role == "Super Admin" ? (
          <ul className="nav nav-pills flex-column mb-auto">
            {optionForSuperAdmin.map((option, index) => (
              <li
                key={index}
                className={
                  location.pathname === option.url
                    ? "active bg-primary rounded"
                    : ""
                }
              >
                <Link
                  to={option.url}
                  className="nav-link text-white d-flex  align-items-center"
                >
                  <FontAwesomeIcon
                    className="bi pe-none me-2"
                    icon={option.icon}
                    width="16"
                    height="16"
                  />
                  <div>{option.name}</div>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <ul className="nav nav-pills flex-column mb-auto">
            {options.map((option, index) => (
              <li
                key={index}
                className={
                  location.pathname === option.url
                    ? "active bg-primary rounded"
                    : ""
                }
              >
                <Link
                  to={option.url}
                  className="nav-link text-white d-flex  align-items-center"
                >
                  <FontAwesomeIcon
                    className="bi pe-none me-2"
                    icon={option.icon}
                    width="16"
                    height="16"
                  />
                  <div>{option.name}</div>
                </Link>
              </li>
            ))}
          </ul>
        )}

        <hr />
        <button
          type="button"
          className="btn btn-danger d-flex justify-content-center align-items-center "
          onClick={handleLogout}
        >
          <IoLogOut />
          <div className="ms-2">Logout</div>
        </button>
      </div>
    </>
  );
};

export default Sidebar;
