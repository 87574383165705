import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";

const AddADepartment = ({ isOpen, onClose }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState("");

  const handleAddClick = () => {
    // e.preventDefault();
    const payload = {
      name: name,
      updatedBy: userId,
    };
    // console.log(payload);
    fetch(`${base_url}/department/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          toast.warn("Failed to add Department!");
          throw new Error("Failed to add Department");
        }
        toast.success("Department Added Successful!");
        // console.log(response);
        setName("");
        onClose();
      })
      .catch((error) => {
        toast.warn("Failed to add Department!");
        console.error("Error adding Department:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add A Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="w-full flex items-center justify-center"
            onSubmit={handleAddClick}
          >
            <div className="flex flex-col gap-3 border-2 w-5/6 bg-white p-10 rounded-lg">
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Department Name"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            Add Department
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddADepartment;
