import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import { AuthProvider } from "./context/useAuth";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import CalendarPage from "./pages/CalendarPage";
import DashboardPage from "./pages/DashboardPage";
import AppointmentPage from "./pages/AppointmentPage";
import ReportsPage from "./pages/ReportsPage";
import DepartmentPage from "./pages/DepartmentPage";
import ClientsPage from "./pages/ClientsPage";
import { UserRoute } from "./routes/UserRoute";
import UserPage from "./pages/UserPage";
import { SuperAdminControl } from "./routes/SuperAdminControl";
import SubDepartmentPage from "./pages/SubDepartmentPage";
import ShilohAlignmentPage from "./pages/ShilohAlignmentPage";

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <UserRoute>
                  <Login />
                </UserRoute>
              }
            ></Route>

            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/calendar"
              element={
                <ProtectedRoute>
                  <CalendarPage />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/appointment"
              element={
                <ProtectedRoute>
                  <AppointmentPage />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/reports"
              element={
                <ProtectedRoute>
                  <ReportsPage />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/department"
              element={
                <ProtectedRoute>
                  <SuperAdminControl>
                    <DepartmentPage />
                  </SuperAdminControl>
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/subdepartment"
              element={
                <ProtectedRoute>
                  <SuperAdminControl>
                    <SubDepartmentPage />
                  </SuperAdminControl>
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/client"
              element={
                <ProtectedRoute>
                  <ClientsPage />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/shilohAlignment"
              element={
                <ProtectedRoute>
                  <ShilohAlignmentPage />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              exact
              path="/user"
              element={
                <ProtectedRoute>
                  <SuperAdminControl>
                    <UserPage />
                  </SuperAdminControl>
                </ProtectedRoute>
              }
            ></Route>

            {/* <Route
              exact
              path="/dummy"
              element={
                // <ProtectedRoute>
                <Login2 />
                // </ProtectedRoute>
              }
            ></Route> */}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
