import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";

const EventModal = ({
  handleOpenEvent,
  event,
  updateAttendenceAPI,
  isOpen,
}) => {
  const [attendenceStatus, setAttendenceStatus] = useState(
    event.attendance === 1
      ? "Present"
      : event.attendance === 0
      ? "Absent"
      : new Date(event.Date) <= new Date()
      ? "Unchecked"
      : "Future Booking"
  );
  const base_url = process.env.REACT_APP_BASE_URL;

  const token = useAuth().token;
  const userId = useAuth().userId;
  const [excusedAbsenceInput, setExcusedAbsenceInput] = useState(false);
  const [remark, setRemark] = useState("");

  const handleAbsent = () => {
    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: event.id,
        attendance: 0,
        remark: null,
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setAttendenceStatus("Absent");
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        updateAttendenceAPI();
      });
  };

  const handlePresent = () => {
    let updatedRemark;
    if (remark == "") {
      updatedRemark = null;
    } else {
      updatedRemark = remark;
    }
    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: event.id,
        attendance: 1,
        remark: updatedRemark,
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Data sent successfully:", data);
        setAttendenceStatus("Present");
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        updateAttendenceAPI();
      });
  };

  const handleExcusedAbsence = () => {
    setExcusedAbsenceInput(true);
  };

  const handleRemarkSubmit = () => {
    if (remark.length == 0) {
      alert("Please enter a valid reason");
      return;
    }
    handlePresent();
  };

  useEffect(() => {
    console.log(`event : `, event);
    setAttendenceStatus(
      event.attendance === 1
        ? "Present"
        : event.attendance === 0
        ? "Absent"
        : new Date(event.Date) <= new Date()
        ? "Unchecked"
        : "Future Booking"
    );
    setExcusedAbsenceInput(false);
    setRemark(event.excused_absence_comment);
  }, [isOpen]);

  return (
    <>
      <Modal show={isOpen} onHide={handleOpenEvent}>
        <Modal.Header closeButton>
          <Modal.Title>Appointment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-4">
            <div className="card mb-4">
              <div className="container pt-3">
                <table border="1" className="table table-striped table-hover">
                  <tbody className="">
                    <tr className="py-2">
                      <th scope="row">Appointment ID:</th>
                      <td>{event.id}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Client Name:</th>
                      <td>{event.client_name}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Department Name:</th>
                      <td>{event.name}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Sub Department Name:</th>
                      <td>{event.sub_department_name}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Attendance:</th>
                      <td>{attendenceStatus}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Date:</th>
                      <td>{moment(event.date).format("MMM Do YYYY")}</td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">Start Time:</th>
                      <td>
                        {moment(event.start_time, "HH:mm:ss").format("hh:mm A")}
                      </td>
                    </tr>
                    <tr className="py-2">
                      <th scope="row">End Time:</th>
                      <td>
                        {moment(event.end_time, "HH:mm:ss").format("hh:mm A")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 ">
            <div className="d-flex justify-content-around">
              <Button variant="secondary" onClick={handleOpenEvent}>
                Close
              </Button>
              <button className="btn btn-danger" onClick={handleAbsent}>
                Absent
              </button>
              <button className="btn btn-primary" onClick={handlePresent}>
                Present
              </button>
              <button
                className="btn btn-warning"
                onClick={handleExcusedAbsence}
              >
                Excused Absence
              </button>
            </div>

            {excusedAbsenceInput && (
              <div className="d-flex gap-4 mt-3">
                <input
                  type="text"
                  placeholder="Enter Reason"
                  className="form-control w-75"
                  name={remark}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  onClick={handleRemarkSubmit}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventModal;
