import React, { useState } from "react";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const UpdateDepartment = ({ isOpen, onClose, data }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [departmentName, setdepartmentName] = useState(data[0].split(":")[1]);

  const handleAddClick = () => {
    if (departmentName === "") {
      toast.warn("Department Name Should not be Empty!!");
      return;
    }

    const payload = {
      DepartmentID: data[0].split(":")[0],
      DepartmentName: departmentName,
      updatedBy: userId,
    };
    // console.log(`payload : `, payload);

    fetch(`${base_url}/department/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          toast.warn("Failed to Update Department");
          throw new Error("Failed to Update Department");
        }
        toast.success("Department Updated Successful!");
        onClose();
      })
      .catch((error) => {
        console.error("Error Updating Department:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Department Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div className="d-flex align-items-center mb-3">
            <input
              type="text"
              id="name"
              className="form-control "
              value={departmentName}
              onChange={(e) => setdepartmentName(e.target.value)}
              placeholder="Department Name"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddClick}>
          Update Department
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateDepartment;
